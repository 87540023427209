<template>
  <div>
    <div class="iframe-div">
      <iframe
        id="videoId"
        :src="youtubeVideoDetails.embed_url"
        allow="autoplay"
        class="iframe-class"
        frameborder="0"
      />
    </div>
    <v-card color="#ECEFF1">
      <br>
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-movie-edit
        </v-icon> Edit Youtube
      </h2>
      <v-card-text>
        <div
          v-if="isDetailsStored"
          class="details-div"
        >
          <v-container fluid>
            <v-card
              class="details-card"
              color="#ECEFF1"
            >
              <br>
              <p
                class="date-style"
              >
                Video URL:-
                <font
                  class="font-my-style"
                >
                  {{ youtubeVideoDetails.url_field }}
                </font>
              </p>
              <p
                class="date-style"
              >
                URL Type:-
                <font
                  class="font-my-style"
                >
                  {{ youtubeVideoDetails.url_type | formatUrlType }}
                </font>
              </p>
            </v-card>
          </v-container>
        </div>
        <v-form
          ref="form"
          v-model="isFormValid"
        >
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="ytVideoFormDetails.title"
                  label="Title*"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Name for your reference - 'ChristmasEve'"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Start date"
                >
                  <v-datetime-picker
                    ref="start"
                    v-model="ytVideoFormDetails.start"
                    label="Start Date time*"
                    :text-field-props="{
                      suffix: userTimeZone,
                      errorMessages: errors,
                      class: 'custom-label-color',
                    }"
                    :date-picker-props="{
                      headerColor: '#37474F',
                      min: minStartDate
                    }"
                    :time-picker-props="{
                      headerColor: '#37474F'
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon
                        large
                        color="#37474F"
                      >
                        mdi-calendar
                      </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon
                        color="#37474F"
                        large
                      >
                        mdi-alarm
                      </v-icon>
                    </template>
                    <template v-slot:actions>
                      <v-btn
                        color="grey lighten-1"
                        text
                        @click.native="cancelStartDialog"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="submitStartDialog"
                      >
                        Ok
                      </v-btn>
                    </template>
                  </v-datetime-picker>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  ref="end"
                  v-model="ytVideoFormDetails.end"
                  label="End Date time"
                  :text-field-props="{
                    hint: 'Leave blank if never ends',
                    persistentHint: true,
                    suffix: userTimeZone
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minEndDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      color="#37474F"
                      large
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      color="#37474F"
                      large
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                  <template v-slot:actions>
                    <v-btn
                      color="red darken-1"
                      text
                      @click.native="clearEndDialog"
                    >
                      Clear
                    </v-btn>
                    <v-btn
                      color="grey lighten-1"
                      text
                      @click.native="cancelEndDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="submitEndDialog"
                    >
                      Ok
                    </v-btn>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                class="text-left"
              >
                <v-btn
                  v-if="showScheduleBtn"
                  small
                  dark
                  class="adv-schdle-btn"
                  color="#FF3700"
                  @click="showAdvancedSchedule = true"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                  Advanced Schedule
                </v-btn>
                <div v-if="showAdvancedSchedule === true">
                  <br>
                </div>
                <advanced-scheduling v-model="showAdvancedSchedule" />
                <advanced-scheduling-list @show-content-form="showContentForm" />
                <advanced-schedule-edit-before-creation
                  v-if="showScheduleContent"
                  :editvalues="editScheduleContent"
                  :scheduleindex="scheduleIndex"
                  @closed="showScheduleContent = false"
                />
                <advanced-schedule-list-for-edit
                  @show-form="showForm"
                  @update-content="fetchLatestYoutubeVideos"
                />
                <advanced-schedule-form-for-edit
                  v-if="showEdit"
                  :editvalues="editScheduleInfo"
                  @closed="showEdit = false"
                  @get-latest="fetchAdvancedScheduleForEdit"
                  @update-content="fetchLatestYoutubeVideos"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-left"
              >
                <v-switch
                  v-model="additionalSettings"
                  label="Additional Setings"
                  color="#FF3700"
                  class="switch-style"
                />
              </v-col>
              <v-container v-if="showAdditionalSettings">
                <v-card
                  class="my-card-style"
                >
                  <p class="my-heading">
                    Additional Settings
                  </p>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-checkbox
                        v-model="ytVideoFormDetails.repeatVideo"
                        label="Repeat Video"
                        color="#FF3700"
                        dense
                        light
                        class="checkbox-style"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-checkbox
                        v-model="ytVideoFormDetails.showSubtitles"
                        label="Show Subtitles"
                        color="#FF3700"
                        dense
                        light
                        class="checkbox-style"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-checkbox
                        v-model="ytVideoFormDetails.mute"
                        label="Mute Video"
                        color="#FF3700"
                        dense
                        light
                        class="checkbox-style"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <br>
                <br>
              </v-container>
            </v-row>
            <v-card-actions>
              <v-btn
                class="mt-2"
                color="red darken-3"
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                class="mt-2"
                color="#FF3700"
                @click="next()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>

<script>

  import Constants from 'src/constants';
  import moment from 'moment-timezone';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import ModuleHelper from 'src/helpers/module-helper';
  import AdvancedScheduling from 'src/views/dashboard/component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from 'src/views/dashboard/component/schedules/AdvancedSchedulingList.vue';
  import AdvancedScheduleListForEdit from 'src/views/dashboard/component/schedules/AdvancedScheduleListForEdit.vue';
  import AdvancedScheduleFormForEdit from 'src/views/dashboard/component/schedules/AdvancedScheduleFormForEdit.vue';
  import AdvancedScheduleEditBeforeCreation from 'src/views/dashboard/component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'EditYoutubeVideo',
    components: {
      'centre-spinner': spinner,
      'advanced-scheduling': AdvancedScheduling,
      'advanced-scheduling-list': AdvancedSchedulingList,
      'advanced-schedule-list-for-edit': AdvancedScheduleListForEdit,
      'advanced-schedule-form-for-edit': AdvancedScheduleFormForEdit,
      'advanced-schedule-edit-before-creation': AdvancedScheduleEditBeforeCreation,
    },
    filters: {
      formatUrlType (val) {
        if (val === 'youtube_video') {
          return 'Youtube Video';
        }
        return 'Youtube Live';
      },
    },
    mixins: [global],
    props: {
      youtubeVideoId: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      ytVideoFormDetails: {
        title: '',
        start: '',
        end: null,
        repeatVideo: false,
        showSubtitles: false,
        mute: false,
      },
      minStartDate: moment().format('YYYY-MM-DD'),
      loading: false,
      helper: new ModuleHelper(),
      showAdvancedSchedule: false,
      editScheduleInfo: {},
      editScheduleContent: {},
      showEdit: false,
      showScheduleContent: false,
      scheduleIndex: 0,
      additionalSettings: false,
      showAdditionalSettings: false,
      isFormValid: false,
      isAdvScheduleFormOpened: false,
    }),
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
       userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_TICKER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
       advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      advancedScheduleDataForEdit () {
        return this.$store.getters['schedule/getAdvancedScheduleForEdit'];
      },
      youtubeVideoDetails () {
        return this.$store.getters['socialmedia/getYoutubeVideoDetails'];
      },
      isDetailsStored () {
        if (Object.keys(this.youtubeVideoDetails).length > 0) {
          return true;
        }
        return false;
      },
      videoEmbedUrl () {
        if (this.isDetailsStored === true && this.youtubeVideoDetails.fetch_Url !== null) {
          return this.youtubeVideoForAdding.fetch_url;
        }
        return '';
      },
    },
    watch: {
      additionalSettings (val) {
        if (val === true) {
          this.showAdditionalSettings = true;
          return;
        }
        this.showAdditionalSettings = false;
      },
    },
    async mounted () {
     this.permissionCheck('get-youtube-content-details') && await this.getYoutubeVideoDetails();
    },
    methods: {
      async getYoutubeVideoDetails () {
        this.loading = true;
        await this.$store.dispatch('socialmedia/fetchYoutubeVideoDetails', {
          youtubeVideoId: this.youtubeVideoId,
        }).then(response => {
          this.fillData();
        }).catch(() => {
          this.loading = false;
        });
      },
      async fillData () {
      this.ytVideoFormDetails.title = this.youtubeVideoDetails.title;
      this.ytVideoFormDetails.start = this.convertStringToLocalDatetime(this.youtubeVideoDetails.start_date);
      this.ytVideoFormDetails.end = this.youtubeVideoDetails.end_date
        ? this.convertStringToLocalDatetime(this.youtubeVideoDetails.end_date)
        : null;
      this.ytVideoFormDetails.showSubtitles = this.youtubeVideoDetails.subtitles;
      this.ytVideoFormDetails.mute = this.youtubeVideoDetails.mute;
      this.ytVideoFormDetails.repeatVideo = this.youtubeVideoDetails.video_repeat;
      this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      this.$store.dispatch('schedule/clearAdvancedSchedule');
      this.permissionCheck('find-weekly-schedule') && await this.fetchAdvancedScheduleForEdit();
      this.loading = false;
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().toDate();
      },
      async fetchAdvancedScheduleForEdit () {
        if (this.youtubeVideoDetails.schedules.length > 0) {
          const schedules = [];
          for (const advancedSchedule of this.youtubeVideoDetails.schedules) {
            schedules.push(advancedSchedule.id);
          }
          for (var i = 0; i < this.youtubeVideoDetails.schedules.length; i++) {
            await this.$store.dispatch('schedule/fetchAdvancedScheduleForEdit', schedules[i]);
          }
        }
      },
      cancel () {
        this.$emit('closed');
        this.$store.dispatch('schedule/clearAdvancedSchedule');
        this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      },
      showForm (val) {
        this.editScheduleInfo = this.advancedScheduleDataForEdit.find(
          schedule => schedule.id === val,
        );
        this.showEdit = true;
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancelStartDialog () {
        this.$refs.start.resetPicker();
        this.$refs.start.init();
      },
      submitStartDialog () {
        this.$refs.start.okHandler();
      },
      cancelEndDialog () {
        this.$refs.end.resetPicker();
        this.$refs.end.init();
      },
      submitEndDialog () {
        this.$refs.end.okHandler();
      },
      clearEndDialog () {
        this.$refs.end.clearHandler();
      },
      next () {
        if (this.$refs.form.validate() === false || this.start === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$store.dispatch('socialmedia/saveEditedYoutubeVideoDetails', this.ytVideoFormDetails);
          this.$emit('success');
        }, 2000);
      },
      fetchLatestYoutubeVideos () {
        this.$emit('fetch-youtube-videos');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
  font-size: 17px;
  color: #37474F;
}
.date-style {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  margin-top: -10px;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.details-div {
  padding-bottom: 10px;
}
.details-card {
  padding-top: 15px;
  padding-bottom: 5px;
  border-left: 1px solid #37474F !important;
}
.checkbox-style{
  margin-top: -20px;
}
::v-deep .checkbox-style .v-label {
 color: #37474F;
}
.require-text-style {
  margin-top: -15px;
  font-style: italic;
}
.switch-style {
  margin-top: -2px;
}
::v-deep .switch-style .v-label {
 color: #37474F;
 font-size: 16px;
}
.my-heading {
  font-size: 20px;
  padding-top: 25px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-card-style {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: -15px;
}
.field-style{
  margin-top: -15px;
}
.iframe-div {
  height:50vh;
}
.iframe-class {
  width:100%;
  height:100%;
  object-fit: cover;
  background:black;
}
</style>
