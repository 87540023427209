<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <p
      v-if="showAlert"
      class="alert-style"
    >
      No Apps Found
    </p>
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="showTicker()"
            link
            @click="getTickerContents()"
          >
            <v-list-item-icon>
              <v-icon class="nav-tab-icon">
                mdi-youtube
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="nav-tab-title"
            >
              Youtube
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-apps
                </v-icon>
              </v-list-item-icon>
              Social Media
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="showTicker()"
            link
            @click="getTickerContents()"
          >
            <v-list-item-icon>
              <v-icon class="nav-tab-icon">
                mdi-youtube
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="nav-tab-title"
            >
              Youtube
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <youtube-videos />
    </div>
  </v-container>
</template>

<script>
import global from 'src/mixins/global';
import ModuleHelper from 'src/helpers/module-helper';
import Constants from 'src/constants';
import YoutubeVideos from './youtube/YoutubeVideos.vue';

 export default {
    name: 'SocialMediaLanding',
    components: {
        'youtube-videos': YoutubeVideos,
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showTickerContents: true,
      helper: new ModuleHelper(),
      showAlert: false,
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_SOCIAL_MEDIA) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      showTicker () {
        if (this.helper.isSubModuleExist(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_TICKER)) {
          this.showTickerContents = true;
          this.showAlert = false;
          return true;
        } else {
          this.showTickerContents = false;
          this.showAlert = true;
          return false;
        }
      },
      getTickerContents () {
        this.showTickerContents = true;
      },
    },
  };

</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.alert-style {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.nav-tab-title {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.nav-tab-icon {
  font-size:25px !important;
  color:#C62828 !important;
  font-weight:bold !important;
}
</style>
