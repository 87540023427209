<template>
  <v-dialog
    v-model="showModal"
    max-width="1200"
    max-height="800"
  >
    <v-card>
      <v-toolbar
        dense
        color="#37474F"
      >
        <v-toolbar-title
          class="white--text"
        >
          Video Preview
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          x-large
          color="#ECEFF1"
          text
          @click="showModal=false"
        >
          close
        </v-btn>
      </v-toolbar>
      <br>
      <p class="my-p-style">
        Watch Video:
        <font
          class="url-style"
          @click="playUrlContent()"
        >
          {{ videoWatchUrl }}
        </font>
      </p>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <iframe
              id="videoId"
              :src="videoEmbedUrl"
              allow="autoplay"
              :width="videoWidth"
              :height="videoHeight"
              frameborder="0"
            />
          </v-row>
        </v-col>
      </v-row>
      <br>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'VideoPreview',
    props: {
      videoEmbedUrl: {
        type: String,
        required: true,
        default: 'https://www.youtube.com/embed',
      },
      videoWatchUrl: {
        type: String,
        required: true,
        default: 'https://www.youtube.com',
      },
    },
    data: () => ({
      showModal: true,
    }),
    computed: {
      videoWidth () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 1000;
        } else {
          return 300;
        }
      },
      videoHeight () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 600;
        } else {
          return 400;
        }
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          const video = document.getElementById('videoId');
          video.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*');
          this.$emit('closed');
        }
      },
    },
    methods: {
      playUrlContent () {
        window.open(this.videoWatchUrl, '_blank');
      },
    },
  };
</script>
<style scoped>
.my-p-style {
  font-size: 16px;
  margin-left: 25px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.url-style {
  color: #FF3700;
  text-decoration: underline;
  cursor: pointer;
}
</style>
