<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
    scrollable
  >
    <edit-youtube-video
      v-if="showYtVideoEdit"
      :youtube-video-id="youtubeVideoId"
      @closed="updationCancelled"
      @success="showDevice"
      @fetch-youtube-videos="getLatestYoutubeVideos"
    />
    <edit-linked-digi-boards
      v-if="showDigiBoardEdit"
      @update-success="updateSuccess"
      @update-failed="updationCancelled"
    />
  </v-dialog>
</template>
<script>
import EditYoutubeVideo from './EditYoutubeVideo.vue';
import EditLinkedDigiBoards from './EditLinkedDigiBoards.vue';

export default {
    name: 'YoutubeVideoEditLanding',
    components: {
        'edit-youtube-video': EditYoutubeVideo,
        'edit-linked-digi-boards': EditLinkedDigiBoards,
    },
    props: {
      youtubeVideoId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        showYtVideoEdit: true,
        showDigiBoardEdit: false,
      };
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      showDevice () {
        this.showYtVideoEdit = false;
        this.showDigiBoardEdit = true;
      },
      updateSuccess () {
        this.showModal = false;
        this.showYtVideoEdit = false;
        this.showDigiBoardEdit = false;
        this.$emit('get-youtube-videos');
      },
      updationCancelled () {
        this.showModal = false;
        this.showYtVideoEdit = false;
        this.showDigiBoardEdit = false;
      },
      getLatestYoutubeVideos () {
        this.$emit('get-youtube-videos');
      },
    },
};
</script>
