<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-youtube
      </v-icon> Youtube
    </h2>
    <v-alert
      v-if="!permissionCheck('get-all-youtube-contents')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-col
        cols="12"
        lg="12"
        class="text-right"
      >
        <v-btn
          v-if="permissionCheck('create-youtube-content')"
          color="#FF3700"
          @click="addYoutubeContent()"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="youtubeVideos.headers"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
        :items="youtubeContents"
        item-key="id"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        disable-sort
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td>{{ item.title }}</td>
            <td>{{ item.start_date | convertStringToLocalDatetime }}</td>
            <td><span v-if="item.end_date !== null">{{ item.end_date | convertStringToLocalDatetime }}</span> <span v-else>No End Date</span></td>
            <td>
              <font
                :color="getColor(item.active)"
                class="font-my-style"
              >
                {{ item.active | statusUpdate }}
              </font>
            </td>
            <td>{{ item.updated_at | diffHumans }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="editYoutubeVideo(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="previewYoutubeVideo(item.embed_url, item.url_field)"
              >
                <v-icon>mdi-play-circle-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="deleteYoutubeVideo(item.id, item.boards)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded)"
              >
                <v-icon
                  v-if="!isExpanded"
                  @click="getBoardDetails(item.boards)"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
          >
            <v-row>
              <v-col
                v-if="showAdvancedSchedule(item.schedules)"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Advanced Schedule</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(schedule, index) in item.schedules">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Start: {{ schedule.start | twelveHour }} End: {{ schedule.end | twelveHour }}
                                </strong>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>
                                  Active Days: {{ schedule.active_days | arrangePretty }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < item.schedules.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Device Details</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(device, index) in boardDetails ">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Device Id: {{ device.external_id }}
                                </strong>
                              </v-list-item-title>
                              <br>
                              <v-list-item-subtitle>
                                <strong>
                                  Device Location: {{ device.location }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < boardDetails.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
    <delete-youtube-video
      v-if="showDelete"
      :title="'Delete this Youtube Video?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <iframe-content-preview
      v-if="showVideoPreviewDialog"
      :video-embed-url="videoEmbedUrl"
      :video-watch-url="videoWatchUrl"
      @closed="showVideoPreviewDialog = false"
    />
    <youtube-video-edit-landing
      v-if="showEdit"
      :youtube-video-id="youtubeVideoId"
      @get-youtube-videos="getYoutubeVideos"
      @closed="showEdit = false"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import IframeContentPreview from 'src/views/dashboard/component/IframeContentPreview';
  import YoutubeVideoEditLanding from './YoutubeVideoEditLanding.vue';

  export default {
    name: 'YoutubeContents',
    components: {
      'centre-spinner': spinner,
      'delete-youtube-video': DeleteDialog,
      'iframe-content-preview': IframeContentPreview,
      'youtube-video-edit-landing': YoutubeVideoEditLanding,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      diffHumans (val) {
        return moment(val).fromNow();
      },
      arrangePretty (val) {
        return val.join(', ');
      },
      twelveHour (val) {
        return moment(val, 'HH:mm').format('hh:mm a');
      },
      statusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Inactive';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        youtubeVideos: {
          headers: [
            { text: 'Name', value: 'title' },
            { text: 'Start', value: 'start' },
            { text: 'End', value: 'end' },
            { text: 'Status', value: 'status' },
            { text: 'Last Updated', value: 'updated_at' },
            { text: 'Edit', value: 'edit' },
            { text: 'Preview', value: 'preview' },
            { text: 'Delete', value: 'delete' },
            { text: 'More', value: 'More' },
          ],
        },
        expanded: [],
        singleExpand: true,
        boardDetails: [],
        showDelete: false,
        deleteId: '',
        boardExternalIds: [],
        showVideoPreviewDialog: false,
        videoWatchUrl: '',
        videoEmbedUrl: '',
        showEdit: false,
        youtubeVideoId: '',
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      youtubeContents () {
        const list = this.$store.getters['socialmedia/getYoutubeVideos'];
        const sortedList = list.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        return sortedList;
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('get-all-youtube-contents') && await this.getYoutubeVideos();
      this.permissionCheck('get-all-youtube-contents') && this.fetchDigiBoards();
      this.loading = false;
    },
    methods: {
      async getYoutubeVideos () {
        this.loading = true;
        await this.$store.dispatch('socialmedia/fetchYoutubeVideos');
        this.loading = false;
      },
      showAdvancedSchedule (schedule) {
        if (schedule.length > 0) {
          return true;
        }
        return false;
      },
      fetchDigiBoards () {
        this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      getBoardDetails (boardDetails) {
        const filteredBoards = this.digiBoards.filter(item => boardDetails.includes(item.external_id));
        this.boardDetails = filteredBoards;
      },
      getColor (active) {
        if (active === true) return '#2E7D32';
        else return '#C62828';
      },
      deleteYoutubeVideo (videoId, boardExternalIds) {
        if (this.permissionCheck('delete-youtube-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDelete = true;
        this.deleteId = videoId;
        this.boardExternalIds = boardExternalIds;
      },
      async deleteConfirmed () {
        this.loading = true;
        this.showDelete = false;
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        await this.$store.dispatch('socialmedia/deleteYoutubeVideo', {
          videoId: this.deleteId,
        })
          .then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Youtube video deleted.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.getYoutubeVideos();
            this.deleteId = '';
            this.loading = false;
          }).catch(() => {
          this.loading = false;
        });
      },
      previewYoutubeVideo (embedUrl, watchUrl) {
        this.videoEmbedUrl = embedUrl;
        this.videoWatchUrl = watchUrl;
        this.showVideoPreviewDialog = true;
      },
      addYoutubeContent () {
        this.$router.push({ name: 'Add Youtube Video' });
      },
      editYoutubeVideo (id) {
        if (this.permissionCheck('get-youtube-content-details') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.youtubeVideoId = id;
        this.showEdit = true;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
  margin-left: 5px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
