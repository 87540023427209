<template>
  <div>
    <v-card
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-television
        </v-icon> Edit Selected Device
      </h2>
      <br>
      <v-alert
        v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
        text
        prominent
        type="warning"
        color="red"
        border="left"
        class="authorization-alert"
      >
        You are not authorized to perform this action. Please contact your administrator.
      </v-alert>
      <base-tree-view-without-actions
        v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
        class="my-tree-view-style"
        :value="treeData"
      >
        <span slot-scope="{node, path, tree}">
          <input
            id="myCheck"
            type="checkbox"
            :checked="node.$checked"
            @change="onCheckBoxChange(node, tree, path)"
          >
          {{ node.text }}
        </span>
      </base-tree-view-without-actions>
      <v-container fluid>
        <v-card-actions>
          <v-btn
            color="red darken-3"
            @click="back()"
          >
            Back
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
            class="mt-2"
            color="#FF3700"
            @click="updateYoutubeVideo()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-container>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import moment from 'moment';

  export default {
    name: 'EditLinkedDigiBoards',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      youtubeVideoDetails () {
        return this.$store.getters['socialmedia/getYoutubeVideoDetails'];
      },
      editedYoutubeVideoDetails () {
        return this.$store.getters['socialmedia/getEditedYoutubeVideoDetails'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.updateExisting();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      updateExisting () {
        const existingBoards = this.youtubeVideoDetails.boards !== undefined ? this.youtubeVideoDetails.boards : [];
        walkTreeData(this.treeData, (node) => {
          if (existingBoards.includes(node.id)) {
            node.$checked = true;
          }
        });
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },

      async updateDigiBoardLink () {
        if (this.permissionCheck('edit-board') === false) {
          this.$emit('update-failed');
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        await this.$store.dispatch('socialmedia/updateYtVideoBoardLink', {
          youtubeVideoId: this.youtubeVideoDetails.id,
          digiBoards: {
            digi_boards: finalResult,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Youtube video updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('update-success');
        }).catch(() => {
          this.loading = false;
          this.$emit('update-failed');
        });
      },
      back () {
        this.$emit('update-failed');
      },
      convertToGmt (date) {
        return moment.utc(date).format();
      },
      async updateYoutubeVideo () {
        this.loading = true;
        const schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          schedules.push(advancedSchedule.id);
        }
        await this.$store.dispatch('socialmedia/updateYoutubeVideo', {
          youtubeVideoId: this.youtubeVideoDetails.id,
          formData: {
            title: this.editedYoutubeVideoDetails.title,
            start_date: this.convertToGmt(this.editedYoutubeVideoDetails.start),
            end_date: this.editedYoutubeVideoDetails.end !== null ? this.convertToGmt(this.editedYoutubeVideoDetails.end) : null,
            video_repeat: this.editedYoutubeVideoDetails.repeatVideo,
            mute: this.editedYoutubeVideoDetails.mute,
            subtitles: this.editedYoutubeVideoDetails.showSubtitles,
            video_type: 'single-video',
            video_start_time: null,
            video_end_time: null,
            schedules: schedules,
            enablejsapi: true,
            autoplay: true,
          },
        }).then(response => {
          this.updateDigiBoardLink();
        }).catch(() => {
          this.loading = false;
          this.$emit('update-failed');
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
input[type="checkbox"] {
accent-color: #FF3700
}
</style>
